import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-9008 Full HD vs IN-5905 HD",
  "path": "/Frequently_Asked_Question/IN-9008_vs_IN-5905/",
  "dateChanged": "2018-03-15",
  "author": "Mike Polinowski",
  "excerpt": "What is the advantage or improvement of IN-9008 Full HD to 5905 HD?",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "Full HD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='FAQs - IN-9008 Full HD vs IN-5905 HD' dateChanged='2020-04-29' author='Mike Polinowski' tag='INSTAR IP Camera' description='What is the advantage or improvement of IN-9008 Full HD to 5905 HD?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/IN-9008_vs_IN-5905/' locationFR='/fr/Frequently_Asked_Question/IN-9008_vs_IN-5905/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "in-9008-full-hd-vs-in-5905-hd",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#in-9008-full-hd-vs-in-5905-hd",
        "aria-label": "in 9008 full hd vs in 5905 hd permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-9008 Full HD vs IN-5905 HD`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: What is the advantage or improvement of IN-9008 Full HD to 5905 HD?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: Key points summarized for the new IN-9008 Full HD series`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "739px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/24e21780fa757f5ab7a4b497b97e7b74/f1d1f/in9008.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAACvElEQVQ4y3WTTUgbURDHZzdpmx4tPXkykUJBq6WHFkwvaRHBg3hIe/KUWnpQay00B+1JKKgHSVqQQqF68aBQEYtNza0YQahIPoRijNpungubzcbdrInZz1dm0UNQ/zDM7PLeb+e9/yzAmZqamiDwJAA9PT1AKYXm5ma4fs0NH8bHIR6Ps5RSl2EYrK7rsL297azRNA2uFAI7OzshGAziYsbr83lvejx334+O3trJZByAZVlwcnICsiy7BEFgbdsG0zSddxfk9/shHA7DwsLCjcbGxu+9vb10YmKSLi0t5TVN+2bb9oBpmi2SJLklSQJCiPMRwzCgUqlAtVqtB3p9Xsbj8WDpa21tpZFolPYPDlkvBwbpr/V1itI0zTJNc0dRlGFCyH1KKYsdIgzBdWpra2POyoZnweC/8NgY9T/tstofPrImp6cNXdNMVVURSiuVCi2Xy1TX9c943EKhwCC4TqFQCObn59m/uRy8HRn5MfBmhD7wP9bv3Guhn2ZmqGEYtmVZBiFESyQSNUVRqCAIaqFQuC2KIoiiyNQBu7u7YW5ujv0Zi8HHaLRreWWF9oVe0FdDr62trd+2LMu0VqtRzLu7u/Tw8JBms9llvEdFUWBvb++iKaiNRIKNr61BWVEC6VRKTCaTFgJ4ns8QQvoJIc+LxWIfx3HDkiQ1cBwH+XyeOTg4qAdGIhHHNdOynGesq9XqH+wI7+v09PRrSZJwM5RKJeB53skIQ8f39/cvjk4qlXJmTRRFVlVVOD4+flcqlTYkSfoiy3J7uVwGQRDcR0dHLp7nXRzHMehwsViETCZz+YDXajWnu46ODidjIBzdRCDWeLzNzU2nW2wCYel0+nIgbpidnYVYLAa2bTP41yiK4lZVlZFl2TFgcXERVldXIZvNQjKZdKAYVwody+VyTsY47xQhU1NTl8a5/gMSIwyTyHenlgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/24e21780fa757f5ab7a4b497b97e7b74/e4706/in9008.avif 230w", "/en/static/24e21780fa757f5ab7a4b497b97e7b74/d1af7/in9008.avif 460w", "/en/static/24e21780fa757f5ab7a4b497b97e7b74/91c3a/in9008.avif 739w"],
              "sizes": "(max-width: 739px) 100vw, 739px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/24e21780fa757f5ab7a4b497b97e7b74/a0b58/in9008.webp 230w", "/en/static/24e21780fa757f5ab7a4b497b97e7b74/bc10c/in9008.webp 460w", "/en/static/24e21780fa757f5ab7a4b497b97e7b74/26073/in9008.webp 739w"],
              "sizes": "(max-width: 739px) 100vw, 739px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/24e21780fa757f5ab7a4b497b97e7b74/81c8e/in9008.png 230w", "/en/static/24e21780fa757f5ab7a4b497b97e7b74/08a84/in9008.png 460w", "/en/static/24e21780fa757f5ab7a4b497b97e7b74/f1d1f/in9008.png 739w"],
              "sizes": "(max-width: 739px) 100vw, 739px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/24e21780fa757f5ab7a4b497b97e7b74/f1d1f/in9008.png",
              "alt": "IN-9008 Full HD",
              "title": "IN-9008 Full HD",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Hardware`}</p>
    <ul>
      <li parentName="ul">{`New and thought-through design / no connection cables with bulky plugs anymore`}</li>
      <li parentName="ul">{`Panasonic WDR image sensor, ideal for difficult lighting situations`}</li>
      <li parentName="ul">{`Built-in microphone incl. Noise detection with adjustable max. level`}</li>
      <li parentName="ul">{`Built-in Panasonic PIR heat sensor to prevent false alarms`}</li>
      <li parentName="ul">{`5 high power 850nm IR LEDs for a good illumination at night`}</li>
      <li parentName="ul">{`Suitable for wall and ceiling mounting thanks to flexible mounting`}</li>
      <li parentName="ul">{`MicroSD memory cards up to 128GB possible. (16GB already installed ex works)`}</li>
      <li parentName="ul">{`WPS for a connection via WLAN without previous LAN cable`}</li>
    </ul>
    <p>{`Software`}</p>
    <ul>
      <li parentName="ul">{`New HTML5 web interface incl. Setup assistant and interactive help function`}</li>
      <li parentName="ul">{`No to few false alarms due to the combination of PIR "and" software-based motion detection. The logical AND link ensures that the camera is almost false alarm free.`}</li>
      <li parentName="ul">{`The video recording on alarm already has 3 seconds (pre-recording) recorded before the alarm.`}</li>
      <li parentName="ul">{`HTTPS and FTPS encryption for secure transmission`}</li>
      <li parentName="ul">{`Record videos to a MicroSD memory card, via FTP or in the cloud`}</li>
      <li parentName="ul">{`Send photos by alarm via email, FTP or on the microSD card`}</li>
      <li parentName="ul">{`Push messages on alarm`}</li>
      <li parentName="ul">{`Continuous recording on SD card possible`}</li>
      <li parentName="ul">{`Time lapse recordings (timelapse) possible on SD, FTP or by email`}</li>
      <li parentName="ul">{`"Two" free ways to access the Internet. P2P - works with all Internet connections, including IPv6 or mobile connections. DDNS - offers the advantage over the web browser on the way to access the camera.`}</li>
      <li parentName="ul">{`Free email server for sending alarm emails`}</li>
      <li parentName="ul">{`Free App for iPhone, iPad, Android, Win Phone, Win8.1 / 10, Blackberry, Kindle`}</li>
      <li parentName="ul">{`German company, this means that the servers for P2P, DDNS, Email as well as for the cloud are all in Germany and therefore the data security is guaranteed.`}</li>
      <li parentName="ul">{`Pure optional cloud which works without any subscription including false alarm / rain detection`}</li>
    </ul>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      